define("ember-svg-jar/inlined/members-paid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.5 24a.502.502 0 01-.498-.458L2.54 18H.5a.5.5 0 01-.5-.5V14c0-3.033 2.467-5.5 5.5-5.5S11 10.967 11 14v3.5a.5.5 0 01-.5.5H8.46l-.462 5.542A.502.502 0 017.5 24h-4zm3.54-1l.462-5.542A.502.502 0 018 17h2v-3c0-2.481-2.019-4.5-4.5-4.5A4.505 4.505 0 001 14v3h2c.258 0 .477.201.498.458L3.96 23h3.08zM5.5 8c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-7c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11.939 9.87a.502.502 0 01-.344-.137 4.48 4.48 0 00-5.006-.811.502.502 0 01-.423-.906 5.472 5.472 0 016.116.99.501.501 0 01-.343.864zM14 8c-1.93 0-3.5-1.57-3.5-3.5S12.07 1 14 1s3.5 1.57 3.5 3.5S15.93 8 14 8zm0-6a2.503 2.503 0 00-2.5 2.5C11.5 5.879 12.622 7 14 7s2.5-1.121 2.5-2.5S15.378 2 14 2zm4.5 22a5.506 5.506 0 01-5.5-5.5c0-3.033 2.467-5.5 5.5-5.5s5.5 2.467 5.5 5.5-2.467 5.5-5.5 5.5zm0-10a4.505 4.505 0 00-4.5 4.5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z\" fill=\"#000\"/><path d=\"M18.5 22a.5.5 0 01-.5-.5V21h-1.5a.5.5 0 010-1h3a.5.5 0 000-1h-2c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5h.5v-.5a.5.5 0 011 0v.5h1.5a.5.5 0 010 1h-3a.5.5 0 000 1h2c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5H19v.5a.5.5 0 01-.5.5z\" fill=\"#000\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});