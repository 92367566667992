define("ember-svg-jar/inlined/desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.036 11.835a.5.5 0 01.463.534 5.524 5.524 0 01-1.1 2.932.5.5 0 11-.798-.602c.525-.697.838-1.53.9-2.401a.5.5 0 01.535-.463zm1.928 0a.5.5 0 01.535.463c.062.87.375 1.704.9 2.401a.5.5 0 01-.798.602 5.525 5.525 0 01-1.1-2.932.5.5 0 01.463-.534z\"/><path d=\"M5.5 15a.5.5 0 01.5-.5h6a.5.5 0 010 1H6a.5.5 0 01-.5-.5zM.834 10.334a.5.5 0 01.5-.5h15.333a.5.5 0 110 1H1.334a.5.5 0 01-.5-.5z\"/><path d=\"M2 1.5a.167.167 0 00-.166.167v10c0 .092.075.166.167.166h14a.167.167 0 00.166-.166v-10a.167.167 0 00-.166-.167H2zm-1.166.167C.834 1.022 1.356.5 2.001.5h14c.644 0 1.166.522 1.166 1.167v10c0 .644-.522 1.166-1.166 1.166H2a1.167 1.167 0 01-1.167-1.166v-10z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 18 16"
    }
  };
});