define("ember-svg-jar/inlined/notification-bell-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"18.875\" cy=\"5.827\" r=\"3\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M9.125 21.75a2.087 2.087 0 004.005 0M11.125 3V.75M18.648 11.961c.189 5.756 1.477 6.789 1.477 6.789h-18s1.5-1.916 1.5-8.25a7.507 7.507 0 019.724-7.165\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "stroke-width": "1.5"
    }
  };
});