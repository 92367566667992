define("ember-svg-jar/inlined/satellite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.854 15.646L19.5 11.293V7.45c1.14-.233 2-1.242 2-2.45 0-1.378-1.121-2.5-2.5-2.5-1.208 0-2.217.86-2.449 2h-3.844L8.354.147a.5.5 0 00-.707 0A11.388 11.388 0 004.278 8.25c0 2.659.909 5.185 2.578 7.228L.124 23.172A.498.498 0 00.5 24h14a.5.5 0 00.376-.83l-3.972-4.539a11.391 11.391 0 0012.949-2.279A.492.492 0 0024 16a.504.504 0 00-.146-.354zM16.457 8.25l1.166-1.167c.262.174.558.301.877.366v2.844L16.457 8.25zM19 3.5c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5c-.413 0-.788-.168-1.06-.44v-.001A1.491 1.491 0 0117.5 5c0-.827.673-1.5 1.5-1.5zm-2.449 2c.065.318.192.614.366.876L15.75 7.543 13.707 5.5h2.844zM1.602 23L7.5 16.259 13.398 23H1.602zm14.148-4.279a10.397 10.397 0 01-7.396-3.075A10.394 10.394 0 015.278 8.25c0-2.621.966-5.099 2.73-7.034l4.139 4.138 6.499 6.498.002.002 4.137 4.137a10.385 10.385 0 01-7.035 2.73z\"/>",
    "attrs": {
      "version": "1",
      "viewBox": "0 0 24 24"
    }
  };
});