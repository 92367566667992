define("ember-svg-jar/inlined/align-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M1 2.75c0-.414.418-.75.933-.75h12.134c.515 0 .933.336.933.75s-.418.75-.933.75H1.933C1.418 3.5 1 3.164 1 2.75zm0 10.5c0-.414.418-.75.933-.75h12.134c.515 0 .933.336.933.75s-.418.75-.933.75H1.933C1.418 14 1 13.664 1 13.25zM4 8c0-.414.398-.75.889-.75h6.222c.491 0 .889.336.889.75s-.398.75-.889.75H4.89C4.398 8.75 4 8.414 4 8z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});