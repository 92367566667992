define("ember-in-viewport/modifiers/in-viewport", ["exports", "ember-modifier", "fast-deep-equal"], function (_exports, _emberModifier, _fastDeepEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const WATCHED_ELEMENTS = false /* DEBUG */ ? new WeakSet() : undefined;
  let InViewportModifier = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class InViewportModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "inViewport", _descriptor, this);
      _defineProperty(this, "name", 'in-viewport');
      _defineProperty(this, "lastOptions", void 0);
    }
    get options() {
      // eslint-disable-next-line no-unused-vars
      const {
        onEnter,
        onExit,
        ...options
      } = this.args.named;
      return options;
    }
    get hasStaleOptions() {
      return !(0, _fastDeepEqual.default)(this.options, this.lastOptions);
    }
    validateArguments() {
      (false && !(this.args.positional.length === 0) && Ember.assert(`'{{in-viewport}}' does not accept positional parameters. Specify listeners via 'onEnter' / 'onExit'.`, this.args.positional.length === 0));
      (false && !(typeof this.args.named.onEnter === 'function' || typeof this.args.named.onExit === 'function') && Ember.assert(`'{{in-viewport}}' either expects 'onEnter', 'onExit' or both to be present.`, typeof this.args.named.onEnter === 'function' || typeof this.args.named.onExit === 'function'));
    }
    onEnter(...args) {
      if (this.args.named.onEnter) {
        this.args.named.onEnter.call(null, this.element, ...args);
      }
      if (!this.options.viewportSpy) {
        this.inViewport.stopWatching(this.element);
      }
    }
    onExit(...args) {
      if (this.args.named.onExit) {
        this.args.named.onExit.call(null, this.element, ...args);
      }
    }
    setupWatcher() {
      (false && !(!WATCHED_ELEMENTS.has(this.element)) && Ember.assert(`'${this.element}' is already being watched. Make sure that '{{in-viewport}}' is only used once on this element and that you are not calling 'inViewport.watchElement(element)' in other places.`, !WATCHED_ELEMENTS.has(this.element)));
      if (false /* DEBUG */) WATCHED_ELEMENTS.add(this.element);
      this.inViewport.watchElement(this.element, this.options, this.onEnter, this.onExit);
      this.lastOptions = this.options;
    }
    destroyWatcher() {
      if (false /* DEBUG */) WATCHED_ELEMENTS.delete(this.element);
      this.inViewport.stopWatching(this.element);
    }
    didInstall() {
      this.setupWatcher();
    }
    didUpdateArguments() {
      if (this.hasStaleOptions) {
        this.destroyWatcher();
        this.setupWatcher();
      }
    }
    didReceiveArguments() {
      this.validateArguments();
    }
    willRemove() {
      this.destroyWatcher();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "inViewport", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onEnter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onExit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onExit"), _class.prototype)), _class));
});