define("ember-svg-jar/inlined/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M6.169 5.667A9.5 9.5 0 113.75 12v-1\"/><path fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M6.25 14l-2.5-3-2.5 3m12-6.5v5h3.5\"/>",
    "attrs": {
      "stroke-width": "1.5",
      "viewBox": "0 0 24 24"
    }
  };
});