define("ember-svg-jar/inlined/arrow2-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#010101\" d=\"M47.863 23.477c.015-.027.038-.047.05-.075.054-.123.087-.258.087-.402s-.033-.279-.087-.402c-.012-.028-.036-.048-.05-.075a.964.964 0 00-.186-.251c-.007-.007-.01-.017-.017-.023l-25-22a1 1 0 00-1.32 1.502L44.35 22H1a1 1 0 100 2h43.35L21.34 44.249a1 1 0 001.32 1.502l25-22c.007-.007.01-.017.017-.023.077-.072.134-.159.186-.251z\"/>",
    "attrs": {
      "viewBox": "0 0 48 48"
    }
  };
});