define("ember-svg-jar/inlined/undo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M12.833 7.286L9.5 4.143 12.833 1M2.034 12.786a8.894 8.894 0 00.281 3.143M4.34 7.511a9.351 9.351 0 00-1.366 1.997m1.39 10.152a9.925 9.925 0 002.556 2.035\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M11.167 22.968c.274.02.555.032.833.032 2.652 0 5.196-.993 7.071-2.762C20.946 18.47 22 16.072 22 13.572c0-2.501-1.054-4.9-2.929-6.668C17.196 5.136 14.652 4.143 12 4.143H9.5\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});