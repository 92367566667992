define("ember-svg-jar/inlined/retry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(12 12)\"><path d=\"M0-12v24\" transform=\"rotate(-45)\"/><path d=\"M0-12v24\" transform=\"rotate(45)\"/></g>",
    "attrs": {
      "class": "retry_svg__retry-animated",
      "viewBox": "0 0 24 24"
    }
  };
});