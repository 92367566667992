define("ember-svg-jar/inlined/twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.028 5.1c.534.146.787.852.367 1.308 0 0-.494.632-.636.804-.378.434-.82.815-1.25 1.19-.292.268-.087.855-.147 1.465-.31 3.143-1.75 6.18-3.826 8.292-.235.238-.573.575-.573.575-.584.542-.758.633-1.087.874-1.853 1.36-4.11 2.127-6.4 2.357l-.765.082H7.445c-.207-.01.148.047-.792-.077-1.838-.243-3.682-.723-5.499-1.824l-.246-.154c-.868-.619-.185-1.538.51-1.437l.09.022s1.582.066 2.763-.228a9.194 9.194 0 001.581-.558c-.492-.216-.97-.486-1.368-.849-.376-.357-.393-.391-.533-.553a5.103 5.103 0 01-.755-1.197l-.211-.474c-.195-.583-.094-.575.134-.847 0 0-.57-.573-.863-1.014a5.344 5.344 0 01-.859-2.48l-.035-.409c-.013-.373.212-.71.587-.797.098-.022.007-.092-.018-.147-.612-1.354-.694-2.917-.06-4.352l.185-.427c.097-.194.12-.188.12-.188s.473-.463.999-.154l.26.264c.35.391.707.78 1.092 1.138 0 0 .691.626 1.36 1.075 1.524 1.024 3.28 1.668 5.138 1.95 0 0 .273-2.346 1.196-3.462a5.305 5.305 0 013.22-1.827L15.702 3h1.012c.351.017.39.063.567.099a5.229 5.229 0 012.39 1.198s1.165-.334 1.981-.753l.466-.218c.582-.084 1.113.476.801 1.16l-.24.616s.174-.03.348-.001zm-7.3-.586a3.815 3.815 0 00-3.174 3.976c.03.616.077.508.081.652.037 1.228-3.14.587-5.086-.19-1.394-.555-2.746-1.325-3.811-2.34 0 0-.362-.326-.722-.692l-.03.122c-.287 1.303.237 2.793 1.237 3.574l.28.197c.26.23.16.133.237.278.225.428-.088 1.043-.587 1.095-.313.032-.697-.055-1.097-.144 0 0 .698 1.628 1.703 2.144.404.208 1.038.402 1.038.402a.784.784 0 01.442 1.142s-.506.41-1.288.45c0 0 1.112 1.382 2.415 1.512.625.05.55.038.612.056.404.112.658.56.496.998-.125.34-.095.263-.345.424 0 0-1.563 1.007-2.53 1.35-.438.155-.889.273-1.343.373 0 0 2.074.572 2.993.631l.316.023 1.073-.006c2.58-.254 5.126-1.073 7.242-2.852 2.792-2.377 3.986-5.951 4.055-9.205.011-.217.026-.27.062-.397.094-.335.303-.63.571-.854 0 0-1.326-.245-.775-1.164l.316-.293.067-.038s.183-.058-.187.028c-1.123.25-1.856-.968-3.05-1.207L16.64 4.5h-.835l-.078.014z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});