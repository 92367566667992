define("ember-svg-jar/inlined/stats-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<desc>Analytics Board Graph Line Streamline Icon: https://streamlinehq.com</desc><path d=\"M4.313 7.188h37.374s2.876 0 2.876 2.875v25.874s0 2.876-2.876 2.876H4.313s-2.875 0-2.875-2.876V10.064s0-2.876 2.875-2.876\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\"/><path d=\"M7.188 28.75l6.037-10.063 5.463 10.063 5.75-5.75 5.75 5.75 8.625-12.938\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\"/>",
    "attrs": {
      "viewBox": "-1 -1 48 48"
    }
  };
});