define("ember-svg-jar/inlined/staff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.staff_svg__cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path class=\"staff_svg__cls-1\" d=\"M22.63 14.869L15 22.5l-3.75.75.75-3.75 7.63-7.631a2.116 2.116 0 012.992 0l.008.009a2.113 2.113 0 010 2.991z\"/><circle class=\"staff_svg__cls-1\" cx=\"7.5\" cy=\"4.875\" r=\"4.125\"/><path class=\"staff_svg__cls-1\" d=\"M13.338 13.919A6.729 6.729 0 00.75 17.25\"/>",
    "attrs": {
      "id": "staff_svg__Regular",
      "viewBox": "0 0 24 24"
    }
  };
});