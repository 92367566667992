define("ember-svg-jar/inlined/warning-stroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.313 2.275a1.5 1.5 0 00-2.626 0l-9.75 18A1.5 1.5 0 002.25 22.5h19.5a1.5 1.5 0 001.313-2.225zM12 18a.375.375 0 01.375.375m-.75 0A.375.375 0 0112 18m0 .75a.375.375 0 01-.375-.375m.75 0a.375.375 0 01-.375.375M12 15V8.25\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});