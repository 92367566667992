define("ember-svg-jar/inlined/arrow-left-stroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.25 23.25L5.53 12.53a.72.72 0 01-.22-.53.72.72 0 01.22-.53L16.25.75\" stroke=\"#000\" stroke-width=\"1.5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});