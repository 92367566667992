define("ember-svg-jar/inlined/integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#343f44\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-1-3h48v48H-1z\" stroke=\"none\"/><g stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M32.932 6.574c.713.428 1.069 1.057 1.068 1.888v9.278l-11 7.076-11-7.076V8.462c0-.831.355-1.46 1.068-1.888l8.8-5.28c.755-.453 1.51-.453 2.264 0l8.8 5.28zM23 13.816v11\"/><path d=\"M34 31.416l-11-6.6 11-7.076 10 6.426c.669.435 1.002 1.052 1 1.85v8.124c.002.798-.331 1.415-1 1.85l-8.8 5.66c-.793.51-1.587.51-2.38 0L23 35.34V24.816m11 6.6V42M23 24.816V35.34l-9.8 6.31c-.793.51-1.587.51-2.38 0l-8.8-5.66c-.678-.43-1.018-1.047-1.02-1.85v-8.124c-.002-.798.331-1.415 1-1.85l10-6.426 11 7.076-11 6.6m0 0L1.262 24.974M12 31.416V42m11-28.184L12.282 7.384m21.436 0L23 13.816m21.738 11.158L34 31.416\"/></g></g>",
    "attrs": {
      "viewBox": "0 0 46 43"
    }
  };
});