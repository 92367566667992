define("@tryghost/ember-promise-modals/services/modals", ["exports", "@tryghost/ember-promise-modals/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    count: Ember.computed('_stack.@each.isClosing', function () {
      return this._stack.filter(modal => !modal.isClosing).length;
    }),
    top: Ember.computed.alias('_stack.lastObject'),
    focusTrapOptions: undefined,
    clickOutsideDeactivates: undefined,
    init() {
      this._super(...arguments);
      this._stack = Ember.A([]);
      (false && !(typeof this.clickOutsideDeactivates === 'undefined') && Ember.deprecate('Defining `clickOutsideDeactivates` directly on the `modals` service is deprecated. ' + `Please use \`focusTrapOptions: { clickOutsideDeactivates: ${this.clickOutsideDeactivates} }\` instead.`, typeof this.clickOutsideDeactivates === 'undefined', {
        id: 'ember-promise-modals.clickOutsideDeactivates-on-modals-service',
        since: '2.1.0',
        until: '3.0.0',
        for: 'ember-promise-modals'
      }));
      if (this.focusTrapOptions !== null) {
        let focusTrapOptions = this.focusTrapOptions ?? {};
        let clickOutsideDeactivates = focusTrapOptions.clickOutsideDeactivates ?? this.clickOutsideDeactivates ?? true;
        this.focusTrapOptions = {
          ...this.focusTrapOptions,
          clickOutsideDeactivates
        };
      }
    },
    willDestroy() {
      this._onLastModalRemoved();
    },
    /**
     * @param {string} name component path
     * @param {*} data passed to the component template
     * @param {ModalOptions} options applied to the modal
     * @returns {Modal}
     */
    open(name, data, options) {
      let modal = new _modal.default(this, name, data, options);
      this._stack.pushObject(modal);
      if (this._stack.length === 1) {
        this._onFirstModalAdded();
      }
      return modal;
    },
    _onFirstModalAdded() {
      document.body.classList.add('epm-scrolling-disabled');
    },
    _onLastModalRemoved() {
      document.body.classList.remove('epm-scrolling-disabled');
    },
    _onModalAnimationStart() {
      document.body.classList.add('epm-animating');
    },
    _onModalAnimationEnd() {
      document.body.classList.remove('epm-animating');
    }
  });
});