define("ember-svg-jar/inlined/idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.5 23.25a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-3zm0-3a.75.75 0 01-.75-.75v-1.157a6.708 6.708 0 01-4.5-6.389C5.263 8.246 8.291 5.229 12 5.229a6.718 6.718 0 014.79 1.994 6.708 6.708 0 011.96 4.78 6.697 6.697 0 01-4.5 6.341V19.5a.75.75 0 01-.75.75h-3zm2.25-1.5v-.962a.75.75 0 01.564-.727 5.236 5.236 0 003.936-5.063 5.217 5.217 0 00-1.524-3.719 5.215 5.215 0 00-3.707-1.551l-.021-.375v.375a5.258 5.258 0 00-5.247 5.231 5.245 5.245 0 003.937 5.102.75.75 0 01.562.726v.962h1.5v.001zm-.75-15a.75.75 0 01-.75-.75V1.5a.75.75 0 011.5 0V3a.75.75 0 01-.75.75zm9 7.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5H21zm-19.5 1.5a.75.75 0 010-1.5H3a.75.75 0 010 1.5H1.5zm4.136-7.114a.743.743 0 01-.53-.22L4.045 4.355a.743.743 0 01-.22-.53c0-.2.078-.389.22-.53a.743.743 0 01.53-.22c.2 0 .389.078.53.22l1.061 1.061c.142.141.22.33.22.53s-.078.389-.22.53a.743.743 0 01-.53.22zm12.728 0a.743.743 0 01-.53-.22.743.743 0 01-.22-.53c0-.2.078-.389.22-.53l1.061-1.061a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-1.061 1.061a.743.743 0 01-.53.22z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});