define("ember-svg-jar/inlined/arrow-right-stroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.5.75l10.72 10.72a.72.72 0 01.22.53.72.72 0 01-.22.53L5.5 23.25\" stroke=\"#000\" stroke-width=\"1.5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});