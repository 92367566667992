define("ember-svg-jar/inlined/arrow-down-stroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.25 7.311L12.53 18.03a.72.72 0 01-.53.22.72.72 0 01-.53-.22L.75 7.311\" stroke=\"#000\" stroke-width=\"1.5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});