define("ember-svg-jar/inlined/analytics-tab-opened-large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.999 3.001c-3.225-.07-6.56 2.851-8.657 5.843A2.04 2.04 0 001 9.998c0 .427.122.838.342 1.155 2.051 2.928 5.377 5.917 8.657 5.846 3.28.071 6.606-2.918 8.66-5.846.22-.317.341-.728.341-1.155 0-.426-.122-.837-.342-1.154-2.1-2.992-5.434-5.913-8.66-5.843z\" stroke=\"#15171A\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M12.37 9.867v0a2.81 2.81 0 01-.412 1.474c-.267.431-.64.759-1.067.95-.425.19-.89.239-1.338.143a2.304 2.304 0 01-1.204-.699 2.707 2.707 0 01-.664-1.349 2.866 2.866 0 01.14-1.538c.185-.485.496-.89.885-1.171.389-.28.839-.426 1.294-.426h0c.303 0 .605.064.888.19.284.128.545.316.767.556.223.24.402.53.525.851.123.322.187.668.187 1.019z\" stroke=\"#15171A\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  };
});