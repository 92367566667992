define("ember-svg-jar/inlined/ghost-orb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "",
    "attrs": {
      "viewBox": "0 0 400 400",
      "style": "background-size:100% 100%;background-repeat:no-repeat;background-image:url(assets/img/logos/orb-black-1.png)"
    }
  };
});