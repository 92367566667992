define("ember-svg-jar/inlined/align-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M1 2.75c0-.414.418-.75.933-.75h12.134c.515 0 .933.336.933.75s-.418.75-.933.75H1.933C1.418 3.5 1 3.164 1 2.75zm0 10.5c0-.414.418-.75.933-.75h12.134c.515 0 .933.336.933.75s-.418.75-.933.75H1.933C1.418 14 1 13.664 1 13.25zM1 8c0-.414.398-.75.889-.75H8.11c.491 0 .889.336.889.75s-.398.75-.889.75H1.89C1.398 8.75 1 8.414 1 8z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});