define("ember-svg-jar/inlined/earth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><circle cx=\"12\" cy=\"12\" r=\"11.25\"/><path d=\"M9.88 23.05c-1.57-2.2-2.63-6.33-2.63-11S8.31 3.15 9.88 1M14.12 23.05c1.57-2.2 2.63-6.33 2.63-11S15.69 3.15 14.12 1M.75 12h22.5M2.05 17.25h19.9M2.05 6.75h19.9\"/></g>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "stroke-width": "1.5"
    }
  };
});