define("ember-svg-jar/inlined/percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.percentage_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path class=\"percentage_svg__a\" d=\"M2.25 21.75l19.5-19.5z\"/><circle class=\"percentage_svg__a\" cx=\"18.75\" cy=\"18.75\" r=\"3\"/><circle class=\"percentage_svg__a\" cx=\"5.25\" cy=\"5.25\" r=\"3\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});