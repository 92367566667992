define("ember-svg-jar/inlined/gift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 21.488H4v-10.75a.75.75 0 00-1.5 0v10.75c0 .914.586 1.5 1.5 1.5h8a.75.75 0 000-1.5z\"/><path d=\"M12.25 21.488h8v-10.75a.75.75 0 011.5 0v10.75c0 .914-.586 1.5-1.5 1.5h-8a.75.75 0 010-1.5z\"/><path d=\"M2.682 6.238h18.636c1.004 0 1.682.546 1.682 1.5v2.25c0 .954-.678 1.5-1.682 1.5H2.682c-1.004 0-1.682-.546-1.682-1.5v-2.25c0-.954.678-1.5 1.682-1.5zm0 1.5c-.239 0-.182-.046-.182 0v2.25c0 .046-.057 0 .182 0h18.636c.239 0 .182.046.182 0v-2.25c0-.046.057 0-.182 0H2.682z\"/><path d=\"M12.75 22.238V6.988a.75.75 0 00-1.5 0v15.25a.75.75 0 001.5 0z\"/><path d=\"M3.5 17.25h17a.75.75 0 000-1.5h-17a.75.75 0 000 1.5zM7.271 5.457a12.506 12.506 0 004.51 2.255.75.75 0 00.92-.918 12.473 12.473 0 00-2.256-4.511C8.947.777 7.705.657 6.671 1.683c-1.033 1.027-.908 2.273.533 3.714l.067.06zm.458-2.709c.397-.395.702-.366 1.596.528.587.755 1.079 1.6 1.456 2.516a11.002 11.002 0 01-2.549-1.487c-.865-.872-.89-1.173-.503-1.557z\"/><path d=\"M16.796 5.397l-.067.06a12.5 12.5 0 01-4.512 2.255.75.75 0 01-.918-.918 12.502 12.502 0 012.315-4.579c1.44-1.438 2.681-1.558 3.715-.531 1.033 1.026.908 2.272-.533 3.713zm-.525-2.649c-.397-.395-.702-.366-1.537.46a11.024 11.024 0 00-1.515 2.584 11.038 11.038 0 002.549-1.487c.865-.872.89-1.173.503-1.557z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});