define("ember-svg-jar/inlined/portal-icon-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.903 12.016a5.007 5.007 0 00-3.031-3.654m-3.835.038a5.002 5.002 0 00-2.879 5.85m2.282 3.046A4.975 4.975 0 0013 18a4.99 4.99 0 004.12-2.167m-1.949 5.387a8.504 8.504 0 005.756-11.295m-2.316-3.31A8.474 8.474 0 0013 4.5a8.461 8.461 0 00-5.608 2.113m-2.28 3.213a8.503 8.503 0 005.914 11.444\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\"/><path d=\"M8.924 24.29c1.273.46 2.645.71 4.076.71 5.52 0 10.17-3.727 11.57-8.803M6.712 2.777A11.994 11.994 0 001 13c0 3.545 1.537 6.731 3.982 8.928m19.867-10.839C23.933 5.369 18.977 1 13 1c-.69 0-1.367.058-2.025.17\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 26 26",
      "fill": "none"
    }
  };
});