define("ember-svg-jar/inlined/facebook-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 27c6.627 0 12-5.373 12-12S21.627 3 15 3 3 8.373 3 15s5.373 12 12 12z\" fill=\"#FF4F67\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M30 15c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15zm-3 0c0 6.627-5.373 12-12 12S3 21.627 3 15 8.373 3 15 3s12 5.373 12 12z\" fill=\"#fff\"/><path d=\"M14.5 11.441C15.434 9.811 16.375 9 18.25 9 20.317 9 22 11.49 22 13.28c0 3.252-4.484 6.777-7.5 8.72-2.713-1.678-7.5-5.468-7.5-8.72C7 11.49 8.684 9 10.75 9c1.875 0 2.809.811 3.75 2.441z\" fill=\"#FEFEFE\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 30 30"
    }
  };
});