define("ember-svg-jar/inlined/copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.333 1.75A1.586 1.586 0 001.75 3.334v5.833a1.586 1.586 0 001.584 1.583h3.5a.75.75 0 010 1.5h-3.5A3.081 3.081 0 01.25 9.167V3.333A3.085 3.085 0 013.333.25h3.5a3.083 3.083 0 013.084 3.083V4.5a.75.75 0 01-1.5 0V3.333A1.586 1.586 0 006.833 1.75h-3.5z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.389 5.25a.806.806 0 00-.806.806v7.388c0 .445.361.806.806.806h5.055c.445 0 .806-.36.806-.806V6.056a.806.806 0 00-.806-.806H8.39zm-2.306.806A2.306 2.306 0 018.389 3.75h5.055a2.305 2.305 0 012.306 2.306v7.388a2.305 2.305 0 01-2.306 2.306H8.39a2.306 2.306 0 01-2.306-2.306V6.056z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});