define("ember-svg-jar/inlined/activity-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M9.295.422a11 11 0 0112.924 11.614.5.5 0 11-.998-.072 10 10 0 10-9.257 9.257.5.5 0 11.072.998A11 11 0 019.295.422zm.288 4.546a6.5 6.5 0 017.961 4.657.5.5 0 11-.968.25 5.499 5.499 0 10-6.7 6.7.5.5 0 01-.251.97 6.5 6.5 0 01-.042-12.577zm1.388 5.02a1.21 1.21 0 01.725.087h.002l11.4 5.21a1.19 1.19 0 01-.163 2.187L18.92 18.94l-1.449 3.987v.002a1.18 1.18 0 01-2.117.223.5.5 0 01-.029-.054l-5.217-11.394a1.21 1.21 0 01.863-1.716zm.312.997a.21.21 0 00-.274.284l.006.013 5.203 11.363a.18.18 0 00.31-.052l.002-.004 1.53-4.21a.5.5 0 01.298-.299l4.24-1.55.017-.005a.19.19 0 00.11-.27.19.19 0 00-.066-.07l-11.375-5.2z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});