define("ember-svg-jar/inlined/tags-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M8 2.706a1 1 0 011-1h15a1 1 0 01.707.293l21 21a1 1 0 01-1.414 1.414L23.586 3.706H9a1 1 0 01-1-1z\"/><path clip-rule=\"evenodd\" d=\"M3.172 8.878A4 4 0 016 7.706h12.258a4 4 0 012.827 1.17l18.328 18.327a4.001 4.001 0 01-1.424 6.573l-7.213 2.707-2.71 7.21a4.002 4.002 0 01-6.573 1.424L3.171 26.791A4 4 0 012 23.962V11.706a4 4 0 011.172-2.828zM6 9.706a2 2 0 00-2 2v12.256a2.003 2.003 0 00.585 1.415l18.322 18.326a2 2 0 003.287-.712l2.87-7.637a1 1 0 01.585-.584l7.638-2.866a2 2 0 00.712-3.287L19.67 10.291a2 2 0 00-1.413-.585H6z\"/><path d=\"M12.25 15.956a2 2 0 100 4 2 2 0 000-4\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 48 48"
    }
  };
});