define("ember-svg-jar/inlined/analytics-tab-conversions-large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.865 10.036A4.241 4.241 0 118.62 2.2a4.241 4.241 0 013.246 7.836zM2.283 17.753A8.747 8.747 0 0113.35 13.79a8.75 8.75 0 014.37 3.962\" stroke=\"#15171A\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  };
});