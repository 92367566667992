define("ember-svg-jar/inlined/sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.47 1.867a4.525 4.525 0 015.02 1.378.4.4 0 01-.618.51 3.725 3.725 0 00-6.597 2.369v.159l.817-.817a.4.4 0 11.565.565l-1.48 1.48a.4.4 0 01-.605 0l-1.48-1.48a.4.4 0 11.565-.565l.818.817v-.16A4.526 4.526 0 014.47 1.868zm5.655 3.107a.4.4 0 01.283.117l1.5 1.5a.4.4 0 01-.566.565l-.822-.822A4.525 4.525 0 012.709 9.23a.4.4 0 01.582-.55 3.725 3.725 0 006.427-2.333l-.81.81a.4.4 0 11-.566-.565l1.5-1.5a.4.4 0 01.283-.117z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 12 12"
    }
  };
});