define("ember-svg-jar/inlined/facebook-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M17.651 8.34h-3.7V6.68a.868.868 0 01.868-.955h2.606V2.25h-3.761c-3.415 0-4.058 2.606-4.058 4.222V8.34H7v3.475h2.606v9.991h4.344v-9.99h3.345l.356-3.476z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});