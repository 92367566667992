define("ember-svg-jar/inlined/twitter-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M120.764 312c144.912 0 224.16-120.023 224.16-224.103 0-3.409-.069-6.802-.223-10.18A160.187 160.187 0 00384 36.93c-14.117 6.274-29.307 10.497-45.243 12.401 16.266-9.75 28.753-25.177 34.645-43.564a158.053 158.053 0 01-50.028 19.118C308.997 9.575 288.53 0 265.866 0c-43.507 0-78.789 35.273-78.789 78.753 0 6.181.693 12.192 2.045 17.96-65.479-3.295-123.542-34.637-162.4-82.294a78.539 78.539 0 00-10.667 39.588c0 27.327 13.908 51.45 35.06 65.563a78.218 78.218 0 01-35.682-9.851c-.011.33-.011.653-.011 1.006 0 38.144 27.156 69.992 63.205 77.209a78.923 78.923 0 01-20.774 2.771 78.434 78.434 0 01-14.807-1.419c10.03 31.295 39.114 54.069 73.597 54.705-26.965 21.129-60.933 33.715-97.85 33.715A160.14 160.14 0 010 276.615c34.867 22.343 76.27 35.38 120.768 35.38\" fill=\"#1DA1F2\"/>",
    "attrs": {
      "viewBox": "0 0 384 312",
      "fill": "none"
    }
  };
});