define("ember-svg-jar/inlined/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.05 1.303a1 1 0 01-.02 1.414L6.597 8l5.433 5.283a1 1 0 01-1.394 1.434L4.756 9a1.4 1.4 0 01-.423-1 1.39 1.39 0 01.423-1l5.88-5.717a1 1 0 011.414.02z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16"
    }
  };
});