define("ember-svg-jar/inlined/moon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.248 11.959A11.254 11.254 0 004.373 1.226a11.25 11.25 0 110 21.466 11.253 11.253 0 007.875-10.733z\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});