define("ember-svg-jar/inlined/check-circle-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.check-circle-simple_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px!important}</style></defs><path class=\"check-circle-simple_svg__a\" d=\"M6 13.223L8.45 16.7a1.049 1.049 0 001.707.051L18 6.828\"/><circle class=\"check-circle-simple_svg__a\" cx=\"12\" cy=\"11.999\" r=\"11.25\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});