define("ember-svg-jar/inlined/star-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.56 8.73a1.51 1.51 0 00-1.41-1h-6.09a.5.5 0 01-.47-.33l-2.18-6.2A1.52 1.52 0 0012 .25a1.49 1.49 0 00-1.4 1L8.41 7.42a.5.5 0 01-.47.33H1.85a1.5 1.5 0 00-1.41 1 1.52 1.52 0 00.45 1.65l5.18 4.3a.5.5 0 01.16.54l-2.18 6.53a1.5 1.5 0 002.31 1.69l5.34-3.92a.49.49 0 01.59 0l5.35 3.92A1.5 1.5 0 0020 21.77l-2.18-6.53a.5.5 0 01.16-.54l5.19-4.31a1.51 1.51 0 00.39-1.66z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});