define("ember-svg-jar/inlined/mobile-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.166 14a.5.5 0 01.5-.5h8.667a.5.5 0 010 1H3.666a.5.5 0 01-.5-.5z\"/><path d=\"M5 1.833a.833.833 0 00-.834.834v12.666c0 .46.373.834.833.834h6c.46 0 .834-.373.834-.834V2.668a.833.833 0 00-.834-.833H5zm-1.834.834c0-1.013.82-1.834 1.833-1.834h6c1.013 0 1.834.821 1.834 1.834v12.666a1.834 1.834 0 01-1.834 1.834H5a1.833 1.833 0 01-1.833-1.834V2.668z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 18"
    }
  };
});