define("ember-svg-jar/inlined/heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.651 5.357c-.878-1.717-2.269-2.728-4.173-3.034-1.904-.305-3.541.22-4.912 1.577L13 5.329 11.434 3.9c-1.371-1.356-3.009-1.881-4.913-1.575-1.904.306-3.295 1.317-4.172 3.035-1.222 2.42-.867 4.582 1.063 6.486L13 21.75l9.588-9.907c1.93-1.904 2.285-4.066 1.063-6.486z\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" stroke=\"#000\" fill=\"none\"/>",
    "attrs": {
      "viewBox": "0 0 26 24"
    }
  };
});