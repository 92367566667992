define("ember-svg-jar/inlined/offer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.offer_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path d=\"M22.939 2.56v6.257a3 3 0 01-.878 2.121L10.5 22.5a1.5 1.5 0 01-2.121 0L1.5 15.62a1.5 1.5 0 010-2.121L13.061 1.938a3 3 0 012.121-.878h6.257a1.5 1.5 0 011.5 1.5z\" class=\"offer_svg__a\"/><path d=\"M17.689 7.81a1.5 1.5 0 110-3 1.5 1.5 0 010 3z\" class=\"offer_svg__a\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});