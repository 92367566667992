define("ember-svg-jar/inlined/audio-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M17.25 12a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm-6.75 5.25a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0z\"/><path clip-rule=\"evenodd\" d=\"M17.25 13.5a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0v-6a.75.75 0 01.75-.75z\"/><path clip-rule=\"evenodd\" d=\"M17.78 13.72a.75.75 0 010 1.06l-2.25 2.25a.75.75 0 11-1.06-1.06l2.25-2.25a.75.75 0 011.06 0z\"/><path clip-rule=\"evenodd\" d=\"M16.72 13.72a.75.75 0 011.06 0l2.25 2.25a.75.75 0 11-1.06 1.06l-2.25-2.25a.75.75 0 010-1.06zM2.25 1.5a.75.75 0 00-.75.75v16.5a.75.75 0 00.75.75h6a.75.75 0 010 1.5h-6A2.25 2.25 0 010 18.75V2.25A2.25 2.25 0 012.25 0h10.629a2.25 2.25 0 011.59.658l2.872 2.873c.422.421.659.994.659 1.59V8.25a.75.75 0 01-1.5 0V5.121a.75.75 0 00-.22-.53L13.41 1.72a.75.75 0 00-.53-.219H2.25z\"/><path clip-rule=\"evenodd\" d=\"M4.825 12.313c-.273.182-.325.352-.325.437 0 .085.052.255.325.437.268.178.681.313 1.175.313s.907-.135 1.175-.313c.273-.182.325-.352.325-.437 0-.085-.052-.255-.325-.437C6.907 12.135 6.494 12 6 12s-.907.135-1.175.313zm-.832-1.248C4.539 10.701 5.251 10.5 6 10.5c.749 0 1.46.201 2.007.565.541.361.993.942.993 1.685 0 .743-.452 1.324-.993 1.685C7.461 14.799 6.749 15 6 15c-.749 0-1.46-.201-2.007-.565C3.452 14.074 3 13.493 3 12.75c0-.743.452-1.324.993-1.685z\"/><path clip-rule=\"evenodd\" d=\"M10.16 7.645c-.33-.164-.7-.23-1.065-.19l-.358.037.26 5.221a.75.75 0 11-1.498.075l-.295-5.93a.75.75 0 01.67-.782l1.062-.113a3.435 3.435 0 013.304 1.64.75.75 0 01-1.284.775 1.935 1.935 0 00-.796-.733z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});