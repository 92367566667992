define("ember-svg-jar/inlined/no-data-subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.no-data-subscription_svg__cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><rect class=\"no-data-subscription_svg__cls-1\" x=\"11.25\" y=\"14.25\" width=\"12\" height=\"9\" rx=\"1.5\" ry=\"1.5\"/><path class=\"no-data-subscription_svg__cls-1\" d=\"M11.25 17.25h12m-6 3h3\"/><circle class=\"no-data-subscription_svg__cls-1\" cx=\"7.5\" cy=\"4.875\" r=\"4.125\"/><path class=\"no-data-subscription_svg__cls-1\" d=\"M.75 17.25a6.753 6.753 0 019.75-6.05\"/>",
    "attrs": {
      "id": "no-data-subscription_svg__Regular",
      "viewBox": "0 0 24 24"
    }
  };
});