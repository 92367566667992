define("ember-svg-jar/inlined/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"16.5\" height=\"13.5\" x=\"3.75\" y=\"9.75\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" rx=\"1.5\" ry=\"1.5\"/><path fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M6.75 9.75V6a5.25 5.25 0 0110.5 0v3.75M12 15v3\"/>",
    "attrs": {
      "stroke-width": "1.5",
      "viewBox": "0 0 24 24"
    }
  };
});