define("ember-svg-jar/inlined/share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<desc>Share 1 Streamline Icon: https://streamlinehq.com</desc><path d=\"M17.25 8.25h1.5a1.5 1.5 0 011.5 1.5v12a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-12a1.5 1.5 0 011.5-1.5h1.5M12 .75v10.5M8.25 4.5L12 .75l3.75 3.75\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});