define("ember-svg-jar/inlined/arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.793 1.043a1 1 0 011.414 0l10.72 10.72a1.748 1.748 0 010 2.475l-10.72 10.72a1 1 0 01-1.414-1.415L15.336 13 4.793 2.457a1 1 0 010-1.414z\" fill=\"#15171a\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 26"
    }
  };
});