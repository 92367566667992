define("ember-svg-jar/inlined/eye-closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M1.5 8S6 14 12 14s10.5-6 10.5-6M9.576 13.68l-.904 2.882m5.752-2.882l.904 2.882M4.922 11.328l-1.816 2.31m15.972-2.31l1.816 2.31\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});