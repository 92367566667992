define("ember-svg-jar/inlined/arrow-right-tail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M.75 8a.75.75 0 01.75-.75h12.857a.75.75 0 110 1.5H1.5A.75.75 0 01.75 8z\"/><path clip-rule=\"evenodd\" d=\"M7.827 1.47a.75.75 0 011.06 0l6 6a.75.75 0 010 1.06l-6 6a.75.75 0 01-1.06-1.06L13.297 8l-5.47-5.47a.75.75 0 010-1.06z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});