define("ember-svg-jar/inlined/email-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.5 1.498h-13a1 1 0 00-1 1v4a1 1 0 001 1h13a1 1 0 001-1v-4a1 1 0 00-1-1zm0 9h-13a1 1 0 00-1 1v4a1 1 0 001 1h13a1 1 0 001-1v-4a1 1 0 00-1-1z\" stroke=\"currentColor\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 18 18"
    }
  };
});