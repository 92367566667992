define("ember-svg-jar/inlined/import-in-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.import-in-progress_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path class=\"import-in-progress_svg__a\" d=\"M7.5 12.001v3H3.75l8.25 8.25 8.25-8.25H16.5v-3M7.5 9.001v-3M7.5 3.001V.751M16.5 9.001v-3M16.5 3.001V.751\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});