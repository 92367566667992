define("ember-svg-jar/inlined/facebook-like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 27c6.627 0 12-5.373 12-12S21.627 3 15 3 3 8.373 3 15s5.373 12 12 12z\" fill=\"#5E92FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M30 15c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15zm-3 0c0 6.627-5.373 12-12 12S3 21.627 3 15 8.373 3 15 3s12 5.373 12 12z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.246 14.078h2.13c.17 0 .304.135.304.305v6.13c0 .17-.134.304-.304.304h-2.13a.31.31 0 01-.313-.305v-6.13a.31.31 0 01.313-.304zm3.687 5.88h6.506a.869.869 0 00.867-.868.887.887 0 00-.554-.806h.16c.457 0 .833-.501.833-.957a.838.838 0 00-.832-.842h-.161v-.071h.465a.844.844 0 00.841-.832.846.846 0 00-.841-.841h-.465v-.072h.465a.844.844 0 00.841-.832.846.846 0 00-.841-.841h-3.642c-.027-.01-.027-.036 0-.09.045-.09.143-.268.295-.537.152-.268.34-.617.439-1.065.098-.447.09-.993.009-1.423-.072-.42-.224-.733-.412-.93a.954.954 0 00-.653-.296c-.233-.008-.475.054-.645.206-.17.161-.259.403-.304.716-.036.322-.009.716-.018.958-.018.241-.062.34-.214.456-.161.116-.421.268-.663.474a5.221 5.221 0 00-.698.797c-.376.483-1.315 1.7-1.315 2.219v4.94c0 .295.242.537.537.537z\" fill=\"#FEFEFE\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 30 30"
    }
  };
});