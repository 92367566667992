define("ember-svg-jar/inlined/paintbrush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.paintbrush_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path class=\"paintbrush_svg__a\" d=\"M10.644 17.865c1.589-1.9.1-4.338 1.513-5.981a2.759 2.759 0 114.18 3.6 6.506 6.506 0 01-5.693 2.381z\"/><path class=\"paintbrush_svg__a\" d=\"M16.62 12.277l6.232-8.253A2.038 2.038 0 1019.4 1.88L14.871 11\"/><path class=\"paintbrush_svg__a\" d=\"M13.5 5.224H3.75a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3v-1.5\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});