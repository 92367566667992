define("ember-svg-jar/inlined/arrow-down-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.469 2.18l11.5 13.143 11.5-13.143\" stroke-width=\"3\" stroke=\"#0B0B0A\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 26 17"
    }
  };
});