define("ember-svg-jar/inlined/discount-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.discount-bubble_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path class=\"discount-bubble_svg__a\" d=\"M22.125 12a3.491 3.491 0 00-2.356-3.338 3.6 3.6 0 00-.61-4.087 3.286 3.286 0 00-3.941-.632 3.341 3.341 0 00-6.436 0 3.288 3.288 0 00-3.942.632 3.6 3.6 0 00-.609 4.087 3.542 3.542 0 000 6.675 3.6 3.6 0 00.609 4.087 3.288 3.288 0 003.942.633 3.341 3.341 0 006.436 0 3.286 3.286 0 003.941-.633 3.6 3.6 0 00.61-4.087A3.49 3.49 0 0022.125 12zm-13.5 3.75l7.5-7.5\"/><circle class=\"discount-bubble_svg__a\" cx=\"9.375\" cy=\"9\" r=\"1.5\"/><circle class=\"discount-bubble_svg__a\" cx=\"15.375\" cy=\"15\" r=\"1.5\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});