define("ember-svg-jar/inlined/email-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.667 13.939a2.66 2.66 0 00-1.956-2.571c-1.263-.345-2.987-.701-4.711-.701-1.724 0-3.448.356-4.71.701a2.66 2.66 0 00-1.957 2.57v1.395h13.334V13.94zM8 8A3.667 3.667 0 108 .667 3.667 3.667 0 008 8z\" stroke=\"currentColor\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});