define("ember-svg-jar/inlined/no-members", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.5 24a.502.502 0 01-.498-.458L2.54 18H.5a.5.5 0 01-.5-.5V14c0-3.033 2.467-5.5 5.5-5.5S11 10.967 11 14v3.5a.5.5 0 01-.5.5H8.46l-.462 5.542A.502.502 0 017.5 24h-4zm3.54-1l.462-5.542A.502.502 0 018 17h2v-3c0-2.481-2.019-4.5-4.5-4.5A4.505 4.505 0 001 14v3h2c.258 0 .477.201.498.458L3.96 23h3.08zM5.5 8c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-7c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11.939 9.87a.502.502 0 01-.344-.137 4.48 4.48 0 00-5.006-.811.502.502 0 01-.423-.906 5.472 5.472 0 016.116.99.501.501 0 01-.343.864zM14 8c-1.93 0-3.5-1.57-3.5-3.5S12.07 1 14 1s3.5 1.57 3.5 3.5S15.93 8 14 8zm0-6a2.503 2.503 0 00-2.5 2.5C11.5 5.879 12.622 7 14 7s2.5-1.121 2.5-2.5S15.378 2 14 2zm4.5 22a5.463 5.463 0 01-3.856-1.583.262.262 0 01-.065-.067A5.446 5.446 0 0113 18.5c0-3.033 2.467-5.5 5.5-5.5a5.46 5.46 0 013.87 1.596l.018.016c.006.005.011.012.016.018A5.462 5.462 0 0124 18.5c0 3.033-2.467 5.5-5.5 5.5zm-2.803-1.989A4.482 4.482 0 0018.5 23c2.481 0 4.5-2.019 4.5-4.5 0-1.02-.349-2.005-.989-2.803l-6.314 6.314zM18.5 14a4.505 4.505 0 00-4.5 4.5c0 1.02.35 2.005.99 2.804l6.315-6.314c-.8-.64-1.785-.99-2.805-.99z\" fill=\"#000\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});